import type { NodeCue } from "~/types/node";

export interface SubtitleChange {
  type:
    | "add"
    | "update"
    | "delete"
    | "title"
    | "split"
    | "merge"
    | "multiSplit"
    | "multiDuplicate"
    | "multiDelete"
    | "multiUpdate"
    | "sort"
    | "removeTranslationTexts"
    | "removeOriginalTexts";
  index?: number;
  oldValue?: any;
  newValue?: any;
  changes?: any[];
}

export const useSubtitleHistory = () => {
  const history = useState<SubtitleChange[]>("history", () => []);
  const currentIndexHistory = useState<number>("currentIndexHistory", () => -1);
  const { subtitles, fileName, hasAutoTranslate } = useSubtitleFile();

  const addChange = (change: SubtitleChange) => {
    // Remove all changes after the current index
    if (currentIndexHistory.value < history.value.length - 1) {
      history.value = history.value.slice(0, currentIndexHistory.value + 1);
    }
    history.value.push(change);
    currentIndexHistory.value++;
  };

  const undo = () => {
    if (currentIndexHistory.value >= 0) {
      const change = history.value[currentIndexHistory.value];
      if (!change) return;
      currentIndexHistory.value--;
      switch (change.type) {
        case "multiSplit":
        case "multiDuplicate":
        case "multiDelete":
        case "multiUpdate":
          if (change.changes) {
            for (const subChange of change.changes.reverse()) {
              undoProcess(subChange);
            }
          }
          break;
        case "removeOriginalTexts":
        case "removeTranslationTexts":
          if (change.changes) {
            for (const subChange of change.changes.reverse()) {
              undoProcess(subChange);
            }
            hasAutoTranslate.value = true;
          }
          break;
        case "sort":
          if (change.changes) {
            change.changes.sort((a, b) => b.newIndex - a.newIndex);
            for (const { oldIndex, newIndex, subtitle } of change.changes) {
              subtitles.value[oldIndex] = subtitle;
            }
          }
          break;
        default:
          undoProcess(change);
          break;
      }
    }
  };

  const redo = () => {
    if (currentIndexHistory.value < history.value.length - 1) {
      currentIndexHistory.value++;
      const change = history.value[currentIndexHistory.value];
      if (!change) return;

      switch (change.type) {
        case "multiSplit":
        case "multiDuplicate":
        case "multiDelete":
        case "multiUpdate":
          if (change.changes) {
            for (const subChange of change.changes) {
              redoProcess(subChange);
            }
          }
          break;
        case "removeOriginalTexts":
        case "removeTranslationTexts":
          if (change.changes) {
            for (const subChange of change.changes.reverse()) {
              redoProcess(subChange);
            }
            hasAutoTranslate.value = false;
          }
          break;
        case "sort":
          if (change.changes) {
            change.changes.sort((a, b) => a.newIndex - b.newIndex);
            for (const { oldIndex, newIndex, subtitle } of change.changes) {
              subtitles.value[newIndex] = subtitle;
            }
          }
          break;
        default:
          redoProcess(change);
          break;
      }
    }
  };

  const undoProcess = (change: SubtitleChange) => {
    switch (change.type) {
      case "add":
        subtitles.value.splice(change.index, 1);
        break;
      case "update":
        subtitles.value[change.index] = change.oldValue;
        break;
      case "delete":
        subtitles.value.splice(change.index, 0, change.oldValue);
        break;
      case "split":
        subtitles.value.splice(change.index, 2, change.oldValue);
        break;
      case "merge":
        subtitles.value.splice(change.index, 1, ...change.oldValue);
        break;
      case "title":
        fileName.value = change.oldValue;
        break;
    }
  };

  const redoProcess = (change: SubtitleChange) => {
    switch (change.type) {
      case "add":
        subtitles.value.splice(change.index, 0, change.newValue);
        break;
      case "update":
        subtitles.value[change.index] = change.newValue;
        break;
      case "delete":
        subtitles.value.splice(change.index, 1);
        break;
      case "split":
        subtitles.value.splice(change.index, 1, ...change.newValue);
        break;
      case "merge":
        subtitles.value.splice(change.index, 2, change.newValue);
        break;
      case "title":
        fileName.value = change.newValue;
        break;
    }
  };

  const clearHistory = () => {
    clearNuxtState(["history", "currentIndexHistory"]);
  };

  const canUndo = () => currentIndexHistory.value >= 0;
  const canRedo = () => currentIndexHistory.value < history.value.length - 1;

  return {
    addChange,
    undo,
    redo,
    canUndo,
    canRedo,
    clearHistory,
  };
};
